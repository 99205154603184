.uploadedPhotos {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
}

.imageRow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.imageContainer {
  width: 120px;
  height: 120px;
  position: relative;
  margin-right: 10px;
  border-radius: 10px;
}

.uploadedImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.closeButton {
  top: 4px;
  right: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}
