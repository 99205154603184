@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Medium.woff2") format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Regular.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Light.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Bold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Black.woff2") format("woff2");
  font-weight: 800;
}

/*---------- root styles---------- */
:root {
  --dark-Theme-Bg: rgb(0, 0, 0);
  --dark-Theme-TextColor: rgba(255, 255, 255, 1);
  --light-Theme-Bg: rgba(216, 242, 255, 1);
  --light-Theme-Bg-light: #f3fbff;
  --light-Theme-TextColor: rgba(51, 51, 51, 1);
  --header-font-family: "Satoshi";
  --body-font-family: "Satoshi";
}
/* --------------------------------- */

/*-------------- body-------------- */
body {
  font-family: var(--body-font-family) !important;
/*  background: var(--light-Theme-Bg) !important; */
  background-color: white !important;
}
/* --------------------------------- */

/* splash */
.splash {
  position: relative;
}
.splash img {
  position: relative;
  z-index: 3;
  animation: jump-up 2s linear infinite;
}

/* ----- */

/* header */
.header {
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
}
.textGray {
  color: #666666;
}
.navbar-brand {
  font-family: var(--header-font-family), serif !important;
}

.header.light {
  background: white !important;
  color: var(--light-Theme-TextColor) !important;
}
.header.dark {
  background: var(--dark-Theme-Bg) !important;
  color: var(--dark-Theme-TextColor) !important;
}
/* --------------------------------- */
.home-feeds-bg {
 /* background-color: var(--light-Theme-Bg-light) !important;*/
  height: 100% !important;
}
/*----------------card-------------- */
.bgProperties {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.Color-light {
  color: #b1b1b1 !important;
}
.fw-bold {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.borderLight {
  border: 1px solid #b1b1b1 !important;
}
.Color-dark {
  color: #c5c5c5 !important;
}
.color-dark_gray {
  color: #333333 !important;
}
.card.light {
  background: white !important;
  border-color: rgba(197, 197, 197, 1) !important;
}
.CategoryBtn {
  border-radius: 10px !important;
  background: var(--light-Theme-Bg) !important;
  border-color: rgba(197, 197, 197, 1) !important;
  p {
    font-weight: 500;
    font-size: 14px;
  }
}
.card.dark {
  background: rgba(0, 0, 0, 1) !important;
  border-color: rgba(34, 34, 34, 1) !important;
}
/* --------------------------------- */

/* --------------Theme Setup--------- */
.light {
  color: var(--light-Theme-TextColor) !important;
  /*background: var(--light-Theme-Bg) !important;*/
  background: white !important;
}
.dark {
  color: var(--dark-Theme-TextColor) !important;
  background: var(--dark-Theme-Bg) !important;
}

/* --------------------------------- */
.react-toggle-track-check {
  background-color: transparent !important;
}
.react-toggle-thumb {
  padding: 12px;
  margin: 1px !important;
  border: 1px solid #0190d2 !important;
  background-color: #0190d2 !important;
}
.react-toggle-track {
  width: 55px !important;
  height: 30px !important;
  border: 1px solid #0190d2;
  background-color: transparent !important;
}
/*--------------global-classes--------*/
.f-1 {
  font-weight: 100 !important;
}
.f-2 {
  font-weight: 200 !important;
}
.f-3 {
  font-weight: 300 !important;
}
.f-4 {
  font-weight: 400 !important;
}
.f-5 {
  font-weight: 500 !important;
}
.f-6 {
  font-weight: 600 !important;
}
.f-7 {
  font-weight: 700 !important;
}
.f-8 {
  font-weight: 800 !important;
}
.full-height {
  height: 100% !important;
}
.full-width {
  width: 100% !important;
}
.full-rounded {
  border-radius: 50% !important;
}
.border-transparent {
  border-color: transparent !important;
}
.border-none {
  border: none !important;
}
.bg-transparent {
  background: transparent !important;
}
.btn-blue {
  gap: 8px !important;
  color: white !important;
  border-radius: 16px !important;
  padding: 16px 24px 16px 24px !important;
  background: rgba(1, 144, 210, 1) !important;
}

.buttonPaddings {
  cursor: pointer;
  padding-top: 16px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
}
.inputPadding {
  padding: 16px 20px !important;
}
.filterBtn {
  width: 72px;
  height: 60px !important;
  border: #c5c5c5 solid 4px;
}

.btn-grey {
  padding-top: 5px;
  padding-bottom: 5px;
  color: black !important;
  border-radius: 16px !important;
  background: rgba(245, 245, 245, 1) !important;
}
.color-blue {
  color: rgba(1, 144, 210, 1) !important;
}
.custom-placeholder::placeholder {
  opacity: 1;
  color: #c5c5c5;
  font-family: inherit;
  font-weight: 400 !important;
}
.rounded-32 {
  border-radius: 32px !important; /* 32px */
}
.rounded-16 {
  border-radius: 16px !important; /* 16px */
}
.rounded-12 {
  border-radius: 12px; /* 12px */
}

/* Assuming this is in your global SCSS file */
.rounded-md {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  @media (max-width: 767px) {
    border-radius: 0;
  }
}

.icon-container {
  gap: 8px;
  width: 46px;
  height: 46px;
  cursor: pointer;
  border-radius: 40px;
  border: 0.5px solid;
  background-color: rgba(216, 242, 255, 1);
}

.icon-input {
  display: flex;
  align-items: center;
  border: 1px solid rgba(197, 197, 197, 1);
  border-radius: 10px;
}
.cursor-pointer {
  cursor: pointer !important;
}
.bg-light-gray {
  background-color: #f5f5f5;
}
.custom-line-height {
  font-size: 12px !important;
  line-height: 16.2px !important;
}
.borderRadius-16 {
  border-radius: 16px !important;
}
.borderRadius-32 {
  border-radius: 32px !important;
}

.text-black {
  color: #000000;
}

/*------------------------------------*/

.slick-prev:before {
  display: inline-block;
  transform: rotate(180deg);
  content: "\279C" !important;
  color: #0190d2 !important;
}
.slick-next:before {
  content: "\279C" !important;
  color: #0190d2 !important;
}

.img-Container-Height {
  height: 60vh;
}

.slick-dots li button:before {
  color: white !important;
}
.slick-dots li.slick-active button:before {
  color: #0190d2 !important;
}
/* ------------main------------------ */
main {
  min-height: 100vh;
}
/*------------------------------------*/

/* -------------- */

/*--------------customizing default classes-------- */
.form-control {
  border-radius: 10px !important;
}
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
.form-check-input {
  border: 1px solid rgba(1, 144, 210, 1) !important;
  border-radius: 2px !important;
}
.btn {
  min-width: 100px !important;
}
.green-light {
  background-color: #d8f2ff !important;
}
.card {
  border-color: rgba(197, 197, 197, 1) !important;
}
.border-Color {
  border-color: #c5c5c5 !important;
}

/*------------------------------------------------- */
.font-32px {
  font-size: 32px;
}
.border-05 {
  border-width: 0.5px;
}
.border-grey {
  border-color: #c5c5c5!important;
}
.opacity-50 {
  opacity: 50%;
}
.button-width {
  width: 320px;
}
.font-weight-700 { 
  font-weight: 700;
}
.card-width {
  width: 416px;
}
@media (min-width: 992px) {
  div.desktop-height {
    height: 470px !important/* or your desired height */
  }
}
