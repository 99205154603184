.custom-modal .modal-content {
    border-radius: 0.5rem !important;
  }
  
  .custom-modal .modal-header {
    border-radius: 0.5rem 0.5rem 0 0 !important;
  }
  
  .custom-modal .modal-body {
    border-radius: 0 0 0.5rem 0.5rem !important;
  }
  .rounded-5 {
    border-radius: 0.5rem; /* Or however large you want the rounding to be */
  }
  