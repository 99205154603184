.nextArrow,
.prevArrow {
  z-index: 1;
  cursor: pointer;
  background: transparent;
  position: absolute !important;
}

.nextArrow {
  color: white;
  right: 0 !important;
  margin-top: -30% !important;
  margin-right: -25px !important;
}

.prevArrow {
  color: white;
  left: 0 !important;
  margin-top: 28% !important;
  margin-left: -25px !important;
}

.closeIcon {
  top: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  right: -45px;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
}
.slick-dots li button:before {
  margin-top: -50px;
  color: #ffffff;
}

.slick-dots li.slick-active button:before {
  color: #ff6600;
}
